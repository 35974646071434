import React from "react"


class TOC extends React.Component {
      render() {
        return(<section class="table-content" id="toc">
        <div class="section-container">
          <div class="section-header">Contenu</div>
            <ul>
              <li class="content-li">
                <a href="#A" class="scroll">
                  <div class="content-letter f-h">A</div>
                  <div class="content-text">Inspiration</div>
                </a>
              </li>
              <li class="content-li">
                <a href="#B" class="scroll">
                  <div class="content-letter f-h">B</div>
                  <div class="content-text">Une bonne économie</div>
                </a>
              </li>
              <li class="content-li">
                <a href="#C" class="scroll">
                  <div class="content-letter f-h">C</div>
                  <div class="content-text">Machine à sous ?</div>
                </a>
              </li>
              <li class="content-li">
                <a href="#D" class="scroll">
                  <div class="content-letter f-h">D</div>
                  <div class="content-text">Mental</div>
                </a>
              </li>
              <li class="content-li">
                <a href="#E" class="scroll">
                  <div class="content-letter f-h">E</div>
                  <div class="content-text">Finances personnelles</div>
                </a>
              </li>
              <li class="content-li">
                <a href="#F" class="scroll">
                  <div class="content-letter f-h">F</div>
                  <div class="content-text">Construire votre machine</div>
                </a>
              </li>
            </ul>
          </div>
      </section>)
        
      }
}
export default TOC