import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/Layout'
import MachineA from '../components/MachineA'
import MachineB from '../components/MachineB'
import MachineC from '../components/MachineC'
import MachineD from '../components/MachineD'
import MachineE from '../components/MachineE'
import MachineF from '../components/MachineF'
import TOC from '../components/TOC'
import FrontHead from '../components/FrontHead'
import Machine0 from '../components/Machine0'

export const IndexPageTemplate = () => (

  <>
  <header className="main-header front-header">
    <div className="main-header-text">
      <h1 className="main-h-text-1">Prends le contrôle de ton économie</h1>
      <h2 className="main-h-text-2">Budget, Épargne et Investissements</h2>
    </div>
  </header>
  <main className="main-content blurred category">
    <FrontHead />
    <div className="grid mb-4">
      <Link to="/finances-personnelles/">
        <div className="grid-cell">
          <img className="img-icon-main" src="/img/finance-personnelles.svg" alt="Les finances personnelles"/>
          <h3>Finances Personnelles</h3>
        </div>
      </Link>
      <Link to="/investir/">
        <div className="grid-cell">
          <img className="img-icon-main" src="/img/investir.svg" alt="Investir"/>
          <h3>Investir</h3>
        </div>
      </Link>
      <Link to="/nos-investissements/">
        <div className="grid-cell">
          <img className="img-icon-main" src="/img/portefeuille.svg" alt="Nos Investissements"/>
          <h3>Nos Investissements</h3>
        </div>
      </Link>
      <Link to="/economiser/">
        <div className="grid-cell">
          <img className="img-icon-main" src="/img/economiser.svg" alt="Economiser"/>
          <h3>Outils pour Économiser</h3>
        </div>
      </Link>
    </div>
    <Machine0 />
    <TOC />
    <MachineA />
    <MachineB />
    <MachineC />
    <MachineD />
    <MachineE />
    <MachineF />
  </main>
  </>
)



const IndexPage = () => {

  return (
    <Layout>
      <IndexPageTemplate />
    </Layout>
  )
}

export default IndexPage
