import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

export default function MachineD() {
  return (
    <StaticQuery
      query={graphql`
        query MachineDQuery {
          markdownRemark(frontmatter: {title: {eq:"frontD"}}) {
            frontmatter {
              title
              img {
                childImageSharp {
                  fluid(maxWidth: 2000, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            html
          }
        }
      `}
      render={data => (
        <><section className="new-section-head" id="D">
          <Img fluid={data.markdownRemark.frontmatter.img.childImageSharp.fluid} className="new-section-image" />
          {/* <img className="new-section-image" src={data.markdownRemark.frontmatter.img} alt="Être inspirer et motivé" /> */}
          <div className="head-text-container">
            <h2 className="head-text-main">Le mental est important en investissement</h2>
            <div className="head-text-second">Apprenez à avoir le bon état d'esprit</div>
            <div className="head-category">D</div>
          </div>
        </section>
          <section className="main-content purple">
            <div className="content-wrapper" dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}>
            </div>
          </section>
        </>
      )}
    />
  )
}