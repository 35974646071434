import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

export default function MachineC() {
  return (
    <StaticQuery
      query={graphql`
        query MachineCQuery {
          markdownRemark(frontmatter: {title: {eq:"frontC"}}) {
            frontmatter {
              title
              img {
                childImageSharp {
                  fluid(maxWidth: 2000, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            html
          }
        }
      `}
      render={data => (
        <><section className="new-section-head" id="C">
          <Img fluid={data.markdownRemark.frontmatter.img.childImageSharp.fluid} className="new-section-image" />
          {/* <img className="new-section-image" src={data.markdownRemark.frontmatter.img} alt="Être inspirer et motivé" /> */}
          <div className="head-text-container">
            <h2 className="head-text-main">Qu'est-ce qu'une machine à sous ?</h2>
            <div className="head-text-second">Faites travailler votre argent</div>
            <div className="head-category">C</div>
          </div>
        </section>
          <section className="main-content yellow">
            <div className="content-wrapper" dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}>
            </div>
          </section>
        </>
      )}
    />
  )
}