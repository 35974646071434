import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

export default function MachineB() {
  return (
    <StaticQuery
      query={graphql`
        query MachineBQuery {
          markdownRemark(frontmatter: {title: {eq:"frontB"}}) {
            frontmatter {
              title
              img {
                childImageSharp {
                  fluid(maxWidth: 2000, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            html
          }
        }
      `}
      render={data => (
        <><section className="new-section-head" id="B">
          <Img fluid={data.markdownRemark.frontmatter.img.childImageSharp.fluid} className="new-section-image" />
          {/* <img className="new-section-image" src={data.markdownRemark.frontmatter.img} alt="Être inspirer et motivé" /> */}
          <div className="head-text-container">
            <h2 className="head-text-main">Une bonne économie</h2>
            <div className="head-text-second">Le voyage commence maintenant</div>
            <div className="head-category">B</div>
          </div>
        </section>
          <section className="main-content sand">
            <div className="content-wrapper" dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}>
            </div>
          </section>
        </>
      )}
    />
  )
}