import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

export default function Machine0() {
  return (
    <StaticQuery
      query={graphql`
        query Machine0Query {
          markdownRemark(frontmatter: {title: {eq:"front0"}}) {
            frontmatter {
              title
              img {
                childImageSharp {
                  fluid(maxWidth: 2000, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      `}
      render={data => (
        <><section className="new-section-head" id="0">
          <Img fluid={data.markdownRemark.frontmatter.img.childImageSharp.fluid} className="new-section-image" />
          <div className="head-text-container">
            <h2 className="head-text-main">Tout le monde peut devenir riche</h2>
            <div className="head-text-second">Le guide complet</div>
          </div>
        </section>
        </>
      )}
    />
  )
}