import { Link } from "gatsby"
import React from "react"


class FrontHead extends React.Component {
      render() {
        return(
          <div className="wrapper">
            <div className="white page">
              <p>Personne ne nous apprend à gérer son économie à l'école 🏫.</p>
              <p>Le résultat ? Beaucoup ont des problèmes d'argent 😱 et ne savent pas comment épargner et mettre de coté quelques euros par mois.</p>
              <p>Ici, nous t'expliquons comment <a href="#E">faire un budget</a>, <Link to="/finances-personnelles/augmenter-revenus/">augmenter tes revenus</Link>, <Link to="/finances-personnelles/reduire-depenses/">réduire tes dépenses</Link>, et <Link to="/investir/bourse/">investir pour devenir riche</Link> 💰.</p>
              <p>Nous voulons t'inspirer et t'aider à devenir riche et confortable avec ton argent, un pas à la fois.</p>
              {/* <img src="/img/logo.svg" /> */}
            </div>
          </div>
      )
        
      }
}
export default FrontHead