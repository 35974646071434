import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

export default function MachineE() {
  return (
    <StaticQuery
      query={graphql`
        query MachineEQuery {
          markdownRemark(frontmatter: {title: {eq:"frontE"}}) {
            frontmatter {
              title
              img {
                childImageSharp {
                  fluid(maxWidth: 2000, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            html
          }
        }
      `}
      render={data => (
        <><section className="new-section-head" id="E">
          <Img fluid={data.markdownRemark.frontmatter.img.childImageSharp.fluid} className="new-section-image" />
          {/* <img className="new-section-image" src={data.markdownRemark.frontmatter.img} alt="Être inspirer et motivé" /> */}
          <div className="head-text-container">
            <h2 className="head-text-main">Prendre soin de vos finances</h2>
            <div className="head-text-second">Commencez par avoir une économie saine</div>
            <div className="head-category">E</div>
          </div>
        </section>
          <section className="main-content black">
            <div className="content-wrapper" dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}>
            </div>
          </section>
        </>
      )}
    />
  )
}